import logo from './logo.svg';
import './App.css';

function App() {
  // 获取 URL 参数
  const queryParams = new URLSearchParams(window.location.search);
  const sessionId = queryParams.get('session_id'); // 获取 session_id 参数

  // 判断是否携带正确的参数
  if (!sessionId) {
    return (
      <div className="App">
        <header className="App-header">
          <p style={{ color: 'red', fontWeight: 'bold' }}>
            Access Denied: Missing or invalid session ID.
          </p>
          <p>
            Please ensure you are using a valid link with the correct <code>session_id</code>.
          </p>
        </header>
      </div>
    );
  }

  // 正常内容
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Session ID: <code>{sessionId}</code>
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
